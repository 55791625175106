import React, { Fragment } from 'react';

export default function IconArrowRight(props) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill={props?.wl?.cor_texto_botao}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 7H12.5M12.5 7L6.5 1M12.5 7L6.5 13"
        stroke={props?.wl.cor_texto_botao}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
