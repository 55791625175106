import Router, { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import styles from './styles.module.css';
import IconArrowRight from '../Icon/IconArrowRight';
/**
 *
 * @param {arrowRight} boolean {arrowRight - true:false - exibir seta para ir para próxima página}
 * @returns
 */

export default function Button(props) {
  const { className, isDisable, arrowRight, handleOnClick, ...restProps } =
    props;
  return (
    <Fragment>
      <button
        style={{
          color: props.isDisable ? '#7a7c7b' : props?.wl?.cor_texto_botao,
          background: props.isDisable
            ? props.wl?.cor_tema_botao_desabilitado
            : props.wl?.cor_tema_botao_solido
            ? props.wl?.cor_tema_botao_solido
            : '1',
        }}
        className={
          props.arrowRight
            ? `${styles.button} ${styles.arrowRight} ${className}`
            : `${styles.button} ${className}`
        }
        onClick={handleOnClick}
        disabled={props.isDisable ? true : false}
        {...restProps}
      >
        {props.name}

        {props.arrowRight ? <IconArrowRight wl={props.wl} /> : ''}
      </button>
    </Fragment>
  );
}
