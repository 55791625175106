import env from "../env";

export const getUrls = (partialUrl = '', forceFullUrl = false) => {
  if (!partialUrl.startsWith('http')) {
    if (partialUrl.startsWith('/wl')) {
      if (!forceFullUrl) {
        return partialUrl;
      }
      //local
      return env('NEXT_PUBLIC_BASE_URL', '') + partialUrl;
    }

    return process.env.NEXT_PUBLIC_APP_SERVER_URL + partialUrl;
  }

  return partialUrl;
};
