import { Router, useRouter } from "next/router";
import DefaultHeader from "../DefaultHeader";
import styles from "./styles.module.css";
import { getUrls } from "../../services/wl/getUrls";
/**
 *
 * @param {*} props {backArrow - true:false - exibir seta para voltar para rota anterior}
 * @returns
 */
export default function Header(props) {
  const router = useRouter();
  
  const logo = getUrls(props.wl?.logo_empresa?.data?.attributes?.url);
  return (
    <DefaultHeader headerTitle={props.headerTitle} wl={props.wl}>
      {props.backArrow ? (
        <img
          onClick={() => router.back()}
          className={`${styles.arrow__left} cursor__pointer`}
          src="/images/icons/arrows/arrow-left.svg"
        />
      ) : (
        ""
      )}

      <img
        src={logo}
        alt="no-img"
        className={styles.logo}
      />
    </DefaultHeader>
  );
}
