import { getUrls } from '../../services/wl/getUrls';
import styles from './styles.module.css';

const DefaultHeader = (props) => {
  const logo = getUrls(props.wl?.logo_empresa?.data?.attributes?.url);

  return (
    <>
      <header className={styles.headerMob}>
        <div
          className={`${styles.bgImg} ${
            !!!props.wl?.usar_configuracao_padrao ? styles.wl : ''
          }`}
          style={{
            background: !!!props.wl?.usar_configuracao_padrao
              ? props.wl?.cor_tema_primaria
              : '',
          }}
        >
          {props.children}
        </div>
        <div
          className={styles.img}
          style={{
            background: !!!props.wl?.usar_configuracao_padrao
              ? props.wl?.cor_tema_primaria
              : '',
          }}
        >
          <div
            className={styles.title}
            style={{
              background: !!!props.wl?.usar_configuracao_padrao
                ? props.wl?.bg_color
                : '',
            }}
          >
            {props.headerTitle}
          </div>
        </div>
      </header>
      <header className={styles.headerDesk}>
        <div
          className={styles.content}
          style={{
            background: !!!props.wl?.usar_configuracao_padrao
              ? props.wl?.cor_tema_primaria
              : '',
          }}
        >
          {props.wl?.usar_configuracao_padrao ? (
            <>
              <img
                src="/images/header/desktop/f10.svg"
                className={styles.f10}
                alt=""
              />
              <img
                src="/images/header/desktop/f20.svg"
                className={styles.f20}
                alt=""
              />
              <img
                src="/images/header/desktop/f30.svg"
                className={styles.f30}
                alt=""
              />
              <img
                src="/images/header/desktop/f40.svg"
                className={styles.f40}
                alt=""
              />
              <img
                src="/images/header/desktop/f50.svg"
                className={styles.f50}
                alt=""
              />
              <img
                src="/images/header/desktop/f60.svg"
                className={styles.f60}
                alt=""
              />
            </>
          ) : (
            ''
          )}
          <img src={logo} alt="no-img" className={styles.logo} />
        </div>

        <div
          className={styles.pontaInferior}
          style={{
            background: !!!props.wl?.usar_configuracao_padrao
              ? props.wl?.cor_tema_primaria
              : '',
          }}
        >
          <div className={styles.barraPontaInferior}>
            <div className={styles.title}>{props.headerTitle}</div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
