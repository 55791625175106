import Image from 'next/image';
import Footer from '../Footer';
import Header from '../Header/Header';
import styles from './styles.module.css';
import { getUrls } from '../../services/wl/getUrls';

export default function SplashScreen(props) {
  const imgSplash = getUrls(props.wl?.splash?.data?.attributes?.url);

  return (
    <>
      {props?.onboarding ? (
        <>
          <div className={styles['splash-screen-layout']}>
            <div className={styles.splash}></div>

            <img className={styles.logo} src={imgSplash} />
          </div>
        </>
      ) : props?.notHeader ? (
        <>
          <div className={styles.section}>
            <img alt="no-img" width={150} height={150} src={imgSplash} />
          </div>
        </>
      ) : (
        <>
          <Header wl={props.wl} />
          <div className={styles.section}>
            {/* <Image alt="no-img" width={150} height={150} src={imgSplash} />
             */}

            <img
              className={styles.logo}
              src={imgSplash}
              width={150}
              height={150}
            />
          </div>
        </>
      )}
    </>
  );
}
