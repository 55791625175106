export default function env(value = 'APP_NAME', $default = '') {
  const current = process.env[value] ?? $default;
  return filteredCurrent(value, current);
}

function filteredCurrent(value, current) {
  if (value == 'NEXT_PUBLIC_BASE_URL' && current.startsWith('https://energisa.')) {
    return 'https://reenergisa.electy.com.br';
  }

  return current;
}